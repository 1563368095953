/* You can add global styles to this file, and also import other style files */
@import "libs/styles/primary.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
// @import 'swiper/scss';
// @import 'swiper/scss/navigation';
// @import 'swiper/scss/pagination';
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Hisense Sans Alfabet';
}

@font-face {
    font-family: "Hisense Sans Alfabet";
    src: url(./assets/hisense-sans-alfabet-cufonfonts/Hisense-Sans-Alfabet-Bold.otf);
    src: url(./assets/hisense-sans-alfabet-cufonfonts/Hisense-Sans-Alfabet-Light.otf);
    src: url(./assets/hisense-sans-alfabet-cufonfonts/Hisense-Sans-Alfabet-Medium.otf);
    src: url(./assets/hisense-sans-alfabet-cufonfonts/Hisense-Sans-Alfabet.otf);
}
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.w-80 {
    width: 80%;
}

.m-auto {
    margin: auto;
}

.flex__wap {
    flex-wrap: wrap;
}

.grid {
    display: grid;
}

.two__columns {
    grid-template-columns: 1fr 1fr;
}

.mt-0{
    margin-top: 0 !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.pt-3 {
    padding-top: 1rem;
}

.mt-5 {
    margin-top: 2rem;
}

.pt-5 {
    padding-top: 2rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-5{
    margin-bottom: 2rem;
}

.my-5{
    margin: 2rem 0;
}

.my-3{
    margin: 1rem 0;
}

.mb-5{
    margin-bottom: 2rem !important;
}

.p-3{
    padding: 1rem;
}

.text__center {
    text-align: center;
}

.text__danger {
    color: #a50034;
}

a {
    text-decoration: none;
    cursor: pointer;
}

ul {
    list-style: none;
}

// swiper overriding style
// .swiper-button-prev,
// .swiper-button-next {
//     width: 50px !important;
//     height: 50px !important;
//     background: rgba(0, 0, 0, 0.5) !important;
//     border: 1px solid #707070 !important;
//     border-radius: 50%;
//     transition: all 0.3s ease-in-out;

//     &::after {
//         display: flex;
//         justify-content: center;
//         width: 100%;
//         height: 100%;
//         align-items: center;
//         font-size: 18px;
//         color: #DBDBDB;
//     }

//     &:hover {
//         transform: scale(1.1);

//         &::after {
//             color: white;
//         }
//     }
// }

// .swiper-button-next {
//     right: 20px;
// }

// .swiper-button-prev {
//     left: 20px;
// }

// .btn-hisense {
//     text-align: center;
//     min-width: 210px;
//     line-height: 100%;
//     font-weight: 400;
//     display: inline-block;
//     padding: 30px;
//     height: 72px;
//     flex-shrink: 0;
//     text-transform: none !important;
//     -webkit-border-radius: 36px;
//     -moz-border-radius: 36px;
//     -ms-border-radius: 36px;
//     border-radius: 36px;
//     color: #fff;
//     background: #6DEBE6;
//     background: -webkit-linear-gradient(0deg, #6DEBE6 0%, #39D5CE 10%, #00AAA6 40%, #067977 60%);
//     background: linear-gradient(90deg, #6DEBE6 0%, #39D5CE 10%, #00AAA6 40%, #067977 60%);
//     background-size: 200%;
//     -webkit-transition: all 0.6s ease;
//     -moz-transition: all 0.6s ease;
//     -o-transition: all 0.6s ease;
//     -ms-transition: all 0.6s ease;
//     transition: all 0.6s ease
// }

// @media screen and (min-width: 1025px) {
//     .btn-hisense:hover {
//         background-position: 0% 0%;
//         background-size: 400%
//     }
// }

// @media screen and (max-width: 1200px) {
//     .btn-hisense {
//         padding: 20px;
//         min-width: 200px;
//         height: 52px
//     }
// }

// @media screen and (min-width:768px) {
//     .mobile-only{
//         display: none;
//     }
// }

// @media screen and (max-width:768px) {
//     .desktop-only{
//         display: none;
//     }
// }



// unminified css

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-loading .slick-list {
    background: #fff url(/.resources/webresources/images/ajax-loader.gif) center center no-repeat;
}

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #fff;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
}

.slick-prev:before {
    content: "←";
}

[dir="rtl"] .slick-prev:before {
    content: "→";
}

.slick-next {
    right: -25px;
}

[dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
}

.slick-next:before {
    content: "→";
}

[dir="rtl"] .slick-next:before {
    content: "←";
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: #000;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    color: #000;
    opacity: 0.75;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-text-size-adjust: none;
    overflow-x: hidden;
}

body {
    width: 100%;
    min-width: 320px;
    height: 100%;
    font-weight: 400;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
figure,
figcaption {
    margin: 0;
    padding: 0;
    outline: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: "";
}

abbr,
acronym {
    border: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

ul li {
    list-style: none;
}

// @font-face {
//     font-family: "Hisense Sans Alfabet";
//     src: url("/.resources/webresources/fonts/HisenseSansAlfabet-Bold.ttf") format("truetype");
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Hisense Sans Alfabet";
//     src: url("/.resources/webresources/fonts/HisenseSansAlfabet-Medium.ttf") format("truetype");
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Hisense Sans Alfabet";
//     src: url("/.resources/webresources/fonts/HisenseSansAlfabet-Regular.ttf") format("truetype");
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: "Hisense Sans Alfabet";
//     src: url("/.resources/webresources/fonts/HisenseSansAlfabet-Light.ttf") format("truetype");
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }
html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    color: #1d1d1f;
    line-height: 150%;
    background: #fff;
    background-color: #fff;
    font-family: "Hisense Sans Alfabet", Arial, "Helvetica Neue", Helvetica, sans-serif;
    // font-family: 'Poppins', sans-serif;
}

body {
    font-size: 1.6rem;
}

body:before {
    content: "desktop";
    display: none;
}

@media (max-width: 767px) {
    body:before {
        content: "mobile";
    }
}

body.overflow {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 100%;
}

@media (min-width: 768px) {
    .mobile-only {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .desktop-only {
        display: none !important;
    }
}

.page-wrapper {
    width: 100%;
    max-width: 2200px;
    margin: 0 auto;
    // padding-top: 80px;
    -webkit-transition: padding-top 0.5s ease-out;
    -moz-transition: padding-top 0.5s ease-out;
    -o-transition: padding-top 0.5s ease-out;
    -ms-transition: padding-top 0.5s ease-out;
    transition: padding-top 0.5s ease-out;
}

// @media (max-width: 1023px) {
//     .page-wrapper {
//         padding-top: 60px;
//     }
// }

@media (max-width: 767px) {
    .page-wrapper {
        overflow: hidden;
    }
}

.page-wrapper .main-container {
    min-height: 500px;
    background: #fff;
    -webkit-transition: background-color 0.6s ease;
    -moz-transition: background-color 0.6s ease;
    -o-transition: background-color 0.6s ease;
    -ms-transition: background-color 0.6s ease;
    transition: background-color 0.6s ease;
}

.visuallyhidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
}

// img.lazy {
//     opacity: 0;
//     -webkit-transition: opacity 0.6s ease, transform 0.6s ease, min-height 0.6s ease;
//     -moz-transition: opacity 0.6s ease, transform 0.6s ease, min-height 0.6s ease;
//     -o-transition: opacity 0.6s ease, transform 0.6s ease, min-height 0.6s ease;
//     -ms-transition: opacity 0.6s ease, transform 0.6s ease, min-height 0.6s ease;
//     transition: opacity 0.6s ease, transform 0.6s ease, min-height 0.6s ease;
// }
// img.lazy.loaded {
//     opacity: 1;
// }
[data-track] {
    opacity: 0;
    -webkit-transition: opacity 0.8s ease, transform 0.8s ease;
    -moz-transition: opacity 0.8s ease, transform 0.8s ease;
    -o-transition: opacity 0.8s ease, transform 0.8s ease;
    -ms-transition: opacity 0.8s ease, transform 0.8s ease;
    transition: opacity 0.8s ease, transform 0.8s ease;
}

[data-track].trackin {
    opacity: 1;
    transform: translateY(0) !important;
}

.mfp-bg {
    -webkit-transition: background-color 0.6s ease;
    -moz-transition: background-color 0.6s ease;
    -o-transition: background-color 0.6s ease;
    -ms-transition: background-color 0.6s ease;
    transition: background-color 0.6s ease;
}

.mfp-bg.mfp-ready {
    background-color: rgba(255, 255, 255, 0.7) !important;
}

.mfp-bg.mfp-removing {
    background-color: rgba(255, 255, 255, 0) !important;
}

.mfp-wrap {
    opacity: 0;
    -webkit-transition: opacity 0.6s ease;
    -moz-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    -ms-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}

.mfp-wrap.mfp-ready {
    opacity: 1;
}

.mfp-wrap.mfp-removing {
    opacity: 0;
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #fff;
    opacity: 1;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #ccc;
}

.mfp-preloader a:hover {
    color: #fff;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background: #000;
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: none;
    background: #444;
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.btn-hisense {
    text-align: center;
    min-width: 210px;
    line-height: 100%;
    font-weight: 400;
    display: inline-block;
    padding: 30px;
    height: 72px;
    flex-shrink: 0;
    text-transform: none !important;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    border-radius: 36px;
    color: #fff;
    background: #6debe6;
    background: -webkit-linear-gradient(0deg, #6debe6 0%, #39d5ce 10%, #00aaa6 40%, #067977 60%);
    background: linear-gradient(90deg, #6debe6 0%, #39d5ce 10%, #00aaa6 40%, #067977 60%);
    background-size: 200%;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

@media screen and (min-width: 1025px) {
    .btn-hisense:hover {
        background-position: 0% 0%;
        background-size: 400%;
    }
}

@media screen and (max-width: 1200px) {
    .btn-hisense {
        padding: 20px;
        min-width: 200px;
        height: 52px;
    }
}

.btn-detail {
    text-align: center;
    min-width: 346px;
    line-height: 100%;
    font-weight: 400;
    display: inline-block;
    padding: 30px;
    height: 72px;
    text-transform: none !important;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    border-radius: 36px;
    color: #fff;
    background-color: #707070;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

@media screen and (min-width: 1025px) {
    .btn-detail:hover {
        background-color: #00aaa6;
    }
}

.btn-detail.small {
    min-width: 168px;
    height: 60px;
    padding: 23px;
}

@media screen and (min-width: 1025px) {
    .btn-detail.small:hover {
        background-color: #ddd;
    }
}

.btn-detail.white {
    background-color: #fff;
    color: #1d1d1f;
}

@media screen and (max-width: 767px) {
    .btn-hisense {
        min-width: none;
        display: flex;
        padding: 0 30px;
        align-items: center;
        justify-content: center;
        height: 56px;
        font-size: 14px;
    }
}

.hidden {
    display: none !important;
}

.mfp-wrap {
    pointer-events: none;
}

.video-lightbox .mfp-inline-holder {
    padding-left: 15px;
    padding-right: 15px;
}

.video-lightbox .mfp-inline-holder .mfp-content {
    width: auto;
    pointer-events: auto;
    height: 90%;
}

.video-lightbox .mfp-inline-holder .mfp-content .cookie-consent-wrap {
    height: 100%;
    overflow: scroll;
}

.video-lightbox .video-wrap {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1182px;
}

.video-lightbox .video-wrap video {
    display: block;
    width: 100%;
    height: auto;
}

.video-lightbox .close {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 60px;
    height: 60px;
    border: none;
    background: #ededed;
    text-indent: -9999px;
    outline: none;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    -moz-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    -ms-transition: background 0.3s ease;
    transition: background 0.3s ease;
}

.video-lightbox .close:hover {
    background: #00cccc;
}

.video-lightbox .close:hover:before,
.video-lightbox .close:hover:after {
    border-color: #fff;
}

.video-lightbox .close:before,
.video-lightbox .close:after {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 20px;
    border-top: 1px solid #00cccc;
    content: "";
    -webkit-transition: border-color 0.3s ease;
    -moz-transition: border-color 0.3s ease;
    -o-transition: border-color 0.3s ease;
    -ms-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
}

.video-lightbox .close:before {
    transform: translateX(-50%) rotate(45deg);
}

.video-lightbox .close:after {
    transform: translateX(-50%) rotate(135deg);
}

img.b2t {
    opacity: 0;
    position: fixed;
    max-width: 120px;
    bottom: 120px;
    right: 20px;
    z-index: 99999;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    cursor: pointer;
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;
}

img.b2t.show {
    opacity: 1;
    transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: visible;
}

@media screen and (max-width: 767px) {
    img.b2t {
        max-width: 85px;
        bottom: 17px;
        right: 12px;
    }
}

.btn-backtotop {
    position: fixed;
    right: 100px;
    bottom: 24px;
    z-index: 11;
    width: 75px;
    height: 42px;
    background-color: #bfbfbf;
    text-indent: -999px;
    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    border-radius: 21px;
    -webkit-transition: background-color 0.3s ease, transform 0.6s ease;
    -moz-transition: background-color 0.3s ease, transform 0.6s ease;
    -o-transition: background-color 0.3s ease, transform 0.6s ease;
    -ms-transition: background-color 0.3s ease, transform 0.6s ease;
    transition: background-color 0.3s ease, transform 0.6s ease;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    // background-image: url(/.resources/webresources/images/common/icon-top.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    overflow: hidden;
}

@media screen and (min-width: 1025px) {
    .btn-backtotop:hover {
        background-color: #00aaa6;
    }
}

.scroll-up .btn-backtotop {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

@media screen and (max-width: 1024px) {
    .btn-backtotop {
        width: 42px;
        right: 10px;
        bottom: 10px;
        background-size: 20px;
    }
}

.form-message {
    font-size: 14px;
    line-height: 120%;
}

.form-message.success {
    color: #00AAA6 !important;
}

.form-message.error {
    color: #f00 !important;
}

.form-message.error a {
    color: #f00 !important;
}

.product-highlight-points {
    margin: 10px 0;
}

.product-highlight-points .product-highlight-point {
    margin: 4px 0;
    display: flex;
    align-items: center;
}

// .product-highlight-points .product-highlight-point * + * {
//     margin-left: 10px;
// }
.product-highlight-points .product-highlight-point .product-highlight-text {
    flex-grow: 1;
    font-size: 24px;
    font-weight: 400;
}

@media (max-width: 767px) {
    .product-highlight-points .product-highlight-point .product-highlight-text {
        font-size: 18px;
    }
}

p .highlight {
    color: #00AAA6;
}

.slick-loading .slick-list {
    background-image: none !important;
}

.model-3d-lightbox .mfp-inline-holder {
    padding-left: 50px;
    padding-right: 50px;
}

@media screen and (max-width: 1023px) {
    .model-3d-lightbox .mfp-inline-holder {
        padding-left: 0;
        padding-right: 0;
    }
}

.model-3d-lightbox .mfp-inline-holder .mfp-content {
    width: 100%;
    pointer-events: auto;
}

.model-3d-lightbox .model-3d-wrap {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1338px;
    height: 100%;
    background: #f8f7f7;
}

.model-3d-lightbox .model-3d-wrap canvas {
    width: 100%;
    height: 100%;
    min-height: 500px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.model-3d-lightbox .model-3d-wrap canvas.ready {
    cursor: url(/.resources/webresources/images/common/cursor-drag.svg) 15 15, auto;
}

.model-3d-lightbox .model-3d-wrap canvas:focus {
    outline: none;
}

.model-3d-lightbox .model-3d-wrap .icon-3d-model {
    position: absolute;
    left: 15px;
    top: 25px;
    background: url(/.resources/webresources/images/common/icon-3d-model.svg) no-repeat left center;
    color: #00AAA6;
    font-weight: 700;
    padding-left: 35px;
}

.model-3d-lightbox .model-3d-wrap .icon-3d-model {
    font-size: 16px;
}

@media screen and (min-width: 1023px) {
    .model-3d-lightbox .model-3d-wrap .icon-3d-model {
        font-size: calc(16px + 4 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .model-3d-lightbox .model-3d-wrap .icon-3d-model {
        font-size: 20px;
    }
}

@media screen and (max-width: 1023px) {
    .model-3d-lightbox .model-3d-wrap .icon-3d-model {
        left: 15px;
        top: 20px;
        padding-left: 30px;
    }
}

.model-3d-lightbox .model-3d-wrap aside {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.model-3d-lightbox .model-3d-wrap aside .ui-btn {
    margin-bottom: 20px;
}

.model-3d-lightbox .model-3d-wrap aside .ui-btn path {
    -webkit-transition: fill 0.4s ease-out;
    -moz-transition: fill 0.4s ease-out;
    -o-transition: fill 0.4s ease-out;
    -ms-transition: fill 0.4s ease-out;
    transition: fill 0.4s ease-out;
}

.model-3d-lightbox .model-3d-wrap aside .ui-btn:last-child {
    margin-bottom: 0;
}

@media (hover: hover),
(-ms-high-contrast: none) {
    .model-3d-lightbox .model-3d-wrap aside .ui-btn:hover path {
        fill: #fff;
    }
}

.model-3d-lightbox .model-3d-wrap nav {
    position: absolute;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn {
    float: left;
    margin-right: 20px;
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 4px solid #4d4d4d;
    border-right: 4px solid #4d4d4d;
    top: 20px;
    -webkit-transition: border-color 0.4s ease-out;
    -moz-transition: border-color 0.4s ease-out;
    -o-transition: border-color 0.4s ease-out;
    -ms-transition: border-color 0.4s ease-out;
    transition: border-color 0.4s ease-out;
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn:last-child {
    margin-right: 0;
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn.rotate-left {
    left: 20px;
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn.rotate-left::before {
    left: 22px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn.rotate-right {
    right: 20px;
}

.model-3d-lightbox .model-3d-wrap nav .ui-btn.rotate-right::before {
    right: 22px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (hover: hover),
(-ms-high-contrast: none) {
    .model-3d-lightbox .model-3d-wrap nav .ui-btn:hover::before {
        border-top-color: #fff;
        border-right-color: #fff;
    }
}

.model-3d-lightbox .model-3d-wrap .ui-btn {
    display: block;
    width: 55px;
    height: 55px;
    background-color: #d0d0d0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    -ms-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out;
}

@media (hover: hover),
(-ms-high-contrast: none) {
    .model-3d-lightbox .model-3d-wrap .ui-btn:hover {
        background-color: #00AAA6;
    }
}

.model-3d-lightbox .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 86px;
    height: 77px;
    border: none;
    text-indent: -9999px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.model-3d-lightbox .close:hover {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.model-3d-lightbox .close:before,
.model-3d-lightbox .close:after {
    position: absolute;
    top: 38px;
    left: 50%;
    width: 36px;
    border-top: 1px solid #777;
    content: "";
}

.model-3d-lightbox .close:before {
    transform: translateX(-50%) rotate(45deg);
}

.model-3d-lightbox .close:after {
    transform: translateX(-50%) rotate(135deg);
}

@keyframes ldio-zqabwc1m7ko {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ldio-zqabwc1m7ko div {
    left: 94px;
    top: 48px;
    position: absolute;
    animation: ldio-zqabwc1m7ko linear 1s infinite;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
}

.ldio-zqabwc1m7ko div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #dddddd;
}

.ldio-zqabwc1m7ko div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #dddddd;
}

.loadingio-spinner-spinner-jkngpq2zy4 {
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}

.loadingio-spinner-spinner-jkngpq2zy4.fade-out {
    opacity: 0;
    pointer-events: none;
}

.ldio-zqabwc1m7ko {
    width: 200px;
    height: 200px;
    position: relative;
    transform: translateZ(0) scale(0.8);
    backface-visibility: hidden;
}

.ldio-zqabwc1m7ko div {
    box-sizing: content-box;
}

.two-box-image-carousel .slick-slide>div {
    display: flex;
    height: 100%;
}

#latest-news h4 {
    font-size: 32px;
}

@media screen and (max-width: 768px) {
    #latest-news h4 {
        font-size: 24px;
        margin-bottom: 18px;
    }
}

#latest-news .news-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
}

@media screen and (max-width: 768px) {
    #latest-news .news-info {
        display: block;
        text-align: center;
    }
}

#latest-news .list-no-result {
    display: none;
    text-align: center;
}

#latest-news .list-no-result.show {
    display: block;
}

#latest-news .main-visual {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

#latest-news .main-visual .slide-item {
    width: 32%;
    display: inline-block;
    height: auto;
    vertical-align: top;
}

@media screen and (max-width: 1162px) {
    #latest-news .main-visual .slide-item {
        width: 48%;
    }
}

@media screen and (max-width: 820px) {
    #latest-news .main-visual .slide-item {
        width: 100%;
    }
}

#latest-news .main-visual .slide-item .text-box-with-image {
    height: 298px;
}

#latest-news .main-visual .slide-item a {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

#latest-news .main-visual .text-box {
    position: relative;
    color: #000;
    padding: 20px 0 0 0;
    margin-bottom: 48px;
}

@media screen and (max-width: 820px) {
    #latest-news .main-visual .text-box {
        margin-bottom: 30px;
    }
}

#latest-news .main-visual .text-box .news-topics {
    color: #00AAA6;
    margin-bottom: 13px;
}

@media screen and (max-width: 820px) {
    #latest-news .main-visual .text-box .news-topics {
        margin-bottom: 7px;
    }
}

#latest-news .main-visual .text-box h4 {
    font-size: 22px;
    height: 140px;
    padding-right: 30px;
}

@media screen and (max-width: 820px) {
    #latest-news .main-visual .text-box h4 {
        height: auto;
    }
}

#latest-news .main-visual .text-box .news-date {
    margin-top: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
}

@media screen and (max-width: 820px) {
    #latest-news .main-visual .text-box .news-date {
        margin-top: 36px;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-text-size-adjust: none;
}

body {
    width: 100%;
    min-width: 320px;
    height: 100%;
    font-weight: 400;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
figure,
figcaption {
    margin: 0;
    padding: 0;
    outline: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: "";
}

abbr,
acronym {
    border: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

ul li {
    list-style: none;
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

h2.sub-title {
    font-weight: bold;
    text-transform: uppercase;
    color: #1d1d1f;
    padding: 30px 30px 30px 30px;
    text-align: center;
}

h2.sub-title {
    font-size: 32px;
}

@media screen and (min-width: 1023px) {
    h2.sub-title {
        font-size: calc(32px + 20 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    h2.sub-title {
        font-size: 52px;
    }
}

h2.sub-title strong {
    color: #00AAA6;
}

@media (max-width: 767px) {
    h2.sub-title {
        font-size: 32px;
        padding: 50px 30px 30px 30px;
    }
}

ul.series-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 48px;
}

@media (max-width: 767px) {
    ul.series-container {
        padding: 0;
    }
}

ul.series-container li {
    width: calc(50% - 5px);
    background: #000;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

ul.series-container li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}

ul.series-container li.col-full {
    width: 100%;
}

ul.series-container li.col-full .banner {
    padding-top: 44.643%;
}

ul.series-container li.col-full .content {
    width: 30%;
}

@media (max-width: 767px) {
    ul.series-container li {
        width: 100%;
    }
}

ul.series-container li .banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 89.686%;
    overflow: hidden;
}

@media (max-width: 767px) {
    ul.series-container li .banner {
        padding-top: 128% !important;
    }
}

ul.series-container li .banner .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

ul.series-container li .banner .img.active {
    z-index: 2;
}

ul.series-container li .banner .img img {
    width: 100%;
    height: auto;
    display: block;
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    -o-transition: all 0.6s ease-out;
    -ms-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
}

ul.series-container li .banner .img img:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

ul.series-container li .top {
    position: absolute;
    top: 50px;
    left: 5%;
    z-index: 10;
    width: 90%;
}

@media (max-width: 1023px) {
    ul.series-container li .top {
        top: 30px;
        left: 5%;
    }
}

@media (max-width: 767px) {
    ul.series-container li .top {
        top: 30px;
    }
}

ul.series-container li .top h3 {
    color: #fff;
    letter-spacing: -0.5px;
    line-height: 100%;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}

ul.series-container li .top h3 {
    font-size: 32px;
}

@media screen and (min-width: 1023px) {
    ul.series-container li .top h3 {
        font-size: calc(32px + 8 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    ul.series-container li .top h3 {
        font-size: 40px;
    }
}

@media (max-width: 1023px) {
    ul.series-container li .top h3 {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    ul.series-container li .top h3 {
        font-size: 32px;
    }
}

ul.series-container li .top nav {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    ul.series-container li .top nav {
        transform: translateX(0) !important;
    }
}

ul.series-container li .top nav a {
    display: block;
    font-size: 18px;
    color: #fff;
    position: relative;
    margin-right: 90px;
    white-space: nowrap;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

@media (max-width: 1023px) {
    ul.series-container li .top nav a {
        font-size: 16px;
        margin-right: 30px;
    }
}

ul.series-container li .top nav a:last-child {
    margin-right: 0;
}

ul.series-container li .top nav a::before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    width: 0;
    border-bottom: 1px solid #00AAA6;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

ul.series-container li .top nav a.active {
    color: #00AAA6;
}

ul.series-container li .top nav a.active::before {
    width: 100%;
}

@media (hover: hover) and (pointer: fine) and (max-width: 1024px) {
    ul.series-container li .top nav a:hover {
        color: #00AAA6;
    }

    ul.series-container li .top nav a:hover::before {
        width: 100%;
    }
}

@media (max-width: 767px) {
    ul.series-container li .top nav.has-scroll {
        justify-content: left;
    }

    ul.series-container li .top nav.has-scroll a {
        margin-right: 40px;
    }
}

ul.series-container li .content {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 10;
    width: 50%;
}

@media (max-width: 1023px) {
    ul.series-container li .content {
        left: 30px;
        bottom: 20px;
    }
}

@media (max-width: 767px) {
    ul.series-container li .content {
        left: 20px;
        bottom: 30px;
        width: 90% !important;
    }
}

ul.series-container li .content .new {
    color: #12ddd7;
    font-size: 14px;
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    ul.series-container li .content .new {
        margin-bottom: 10px;
    }
}

ul.series-container li .content p {
    color: #fff;
    line-height: 120%;
    margin-bottom: 15px;
}

ul.series-container li .content p {
    font-size: 18px;
}

@media screen and (min-width: 1023px) {
    ul.series-container li .content p {
        font-size: calc(18px + 6 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    ul.series-container li .content p {
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    ul.series-container li .content p {
        font-size: 18px;
        line-height: 150%;
        width: 70%;
    }
}

ul.series-container li .content .btn-container {
    display: inline-flex;
}

ul.series-container li .content .btn-container a {
    display: block;
    font-size: 16px;
    color: #fff;
    position: relative;
    white-space: nowrap;
    padding-top: 7px;
    -webkit-transition: color 0.4s ease-out;
    -moz-transition: color 0.4s ease-out;
    -o-transition: color 0.4s ease-out;
    -ms-transition: color 0.4s ease-out;
    transition: color 0.4s ease-out;
}

@media (max-width: 767px) {
    ul.series-container li .content .btn-container a {
        padding-top: 12px;
    }
}

ul.series-container li .content .btn-container a:last-child {
    margin-right: 0;
}

@media (hover: hover) and (pointer: fine) {
    ul.series-container li .content .btn-container a:hover {
        color: #00AAA6;
    }
}

ul.series-container li .content .btn-container a.btn-more {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    overflow: hidden;
    margin-right: 25px;
    padding: 6px 16px;
}

@media (max-width: 767px) {
    ul.series-container li .content .btn-container a.btn-more {
        margin-right: 20px;
        padding: 12px 25px;
        width: 90px;
    }
}

ul.series-container li .content .btn-container a.btn-more:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #6debe6;
    background: -webkit-linear-gradient(0deg, #6debe6 0%, #18c4bf 30%, #007b8b 100%);
    background: linear-gradient(90deg, #6debe6 0%, #18c4bf 30%, #007b8b 100%);
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    z-index: -1;
}

ul.series-container li .content .btn-container a.btn-more:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 25px;
    opacity: 1;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    z-index: 1;
    border: 2px solid #fff;
}

@media (hover: hover) and (pointer: fine) {
    ul.series-container li .content .btn-container a.btn-more:hover {
        border: none;
        color: #fff;
    }

    ul.series-container li .content .btn-container a.btn-more:hover:before {
        opacity: 1;
    }

    ul.series-container li .content .btn-container a.btn-more:hover:after {
        opacity: 0;
    }
}

.about-container {
    padding: 0 43px 90px 43px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .about-container {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .about-container.about-container.slick-dotted {
        padding: 0 0 40px 0;
    }
}

.about-container .slide-item {
    position: relative;
    padding: 0 5px;
}

.about-container .slide-item .content {
    position: absolute;
    left: 10%;
    top: 35%;
    z-index: 10;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1023px) {
    .about-container .slide-item .content {
        top: 30%;
    }
}

@media (max-width: 767px) {
    .about-container .slide-item .content {
        top: 25%;
    }
}

.about-container .slide-item .content h3 {
    color: #fff;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 30px;
    text-align: center;
}

@media (max-width: 1023px) {
    .about-container .slide-item .content h3 {
        margin-bottom: 10px;
    }
}

.about-container .slide-item .content p {
    color: #fff;
    line-height: 120%;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}

.about-container .slide-item .content p {
    font-size: 20px;
}

@media screen and (min-width: 1023px) {
    .about-container .slide-item .content p {
        font-size: calc(20px + 12 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .about-container .slide-item .content p {
        font-size: 32px;
    }
}

@media (max-width: 1023px) {
    .about-container .slide-item .content p {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .about-container .slide-item .content p {
        font-size: 24px;
        line-height: 150%;
    }
}

.about-container .slide-item .content p span {
    color: #00AAA6;
}

.about-container .slide-item .content a.btn-more {
    display: block;
    font-size: 16px;
    color: #fff;
    position: relative;
    padding: 18px 32px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.about-container .slide-item .content a.btn-more:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #6debe6;
    background: -webkit-linear-gradient(0deg, #6debe6 0%, #18c4bf 30%, #007b8b 100%);
    background: linear-gradient(90deg, #6debe6 0%, #18c4bf 30%, #007b8b 100%);
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    z-index: -1;
}

.about-container .slide-item .content a.btn-more:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 30px;
    opacity: 1;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    z-index: 1;
    border: 2px solid #fff;
}

@media (max-width: 1023px) {
    .about-container .slide-item .content a.btn-more {
        opacity: 1;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media (hover: hover) and (pointer: fine) {
    .about-container .slide-item .content a.btn-more:hover {
        border: none;
        color: #fff;
    }

    .about-container .slide-item .content a.btn-more:hover:before {
        opacity: 1;
    }

    .about-container .slide-item .content a.btn-more:hover:after {
        opacity: 0;
    }
}

.about-container .slide-item .banner {
    position: relative;
}

.about-container .slide-item .banner:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: none;
}

.about-container .slide-item .banner img {
    display: block;
    width: 100%;
    height: auto;
}

@media (hover: hover) and (pointer: fine) {
    .about-container .slide-item:hover a.btn-more {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@media (hover: hover) and (pointer: fine) and (max-width: 1023px) {
    .about-container .slide-item:hover a.btn-more {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.isIE .about-container .slide-item:hover a.btn-more {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

@media (max-width: 1023px) {
    .isIE .about-container .slide-item:hover a.btn-more {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.about-container .slick-dots {
    bottom: -7px;
    width: auto;
    left: 50%;
    padding: 10px 19px;
    display: flex;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.about-container .slick-dots li {
    width: 24px;
    height: 24px;
    margin: 0;
    display: block;
}

.about-container .slick-dots li button {
    position: relative;
    width: 24px;
    height: 24px;
}

.about-container .slick-dots li button:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    background-color: #1d1d1f;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    opacity: 0.3;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media (hover: hover) and (pointer: fine) {
    .about-container .slick-dots li button:hover:before {
        top: 6px;
        left: 6px;
        width: 12px;
        height: 12px;
        opacity: 1;
    }
}

.about-container .slick-dots li.slick-active button:before {
    top: 6px;
    left: 6px;
    width: 12px;
    height: 12px;
    opacity: 1;
}

.about-container.highlight {
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .about-container.highlight .slide-item {
        padding: 0;
    }
}

.about-container.highlight .slide-item .content p {
    font-size: 32px;
}

@media screen and (min-width: 1023px) {
    .about-container.highlight .slide-item .content p {
        font-size: calc(32px + 32 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .about-container.highlight .slide-item .content p {
        font-size: 64px;
    }
}

@media (max-width: 767px) {
    .about-container.highlight .slide-item .content p {
        font-size: 24px;
        line-height: 150%;
    }
}

.about-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 55px 30px 90px 30px;
}

@media (max-width: 767px) {
    .about-btn-container {
        padding: 25px 30px 90px 30px;
    }
}

.about-btn-container a.btn-hisense {
    height: 60px;
    padding: 24px;
    min-width: 262px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
}

a.btn-arrow,
.slick-arrow {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    background: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid #707070 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: block;
    top: 50%;
    z-index: 50;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-indent: -9999px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

a.btn-arrow::before,
.slick-arrow::before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    border-top: 2px solid #dbdbdb;
    border-right: 2px solid #dbdbdb;
    top: 18px;
}

a.btn-arrow.left,
a.btn-arrow.slick-prev,
.slick-arrow.left,
.slick-arrow.slick-prev {
    left: 20px;
}

a.btn-arrow.left::before,
a.btn-arrow.slick-prev::before,
.slick-arrow.left::before,
.slick-arrow.slick-prev::before {
    left: 20px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

a.btn-arrow.right,
a.btn-arrow.slick-next,
.slick-arrow.right,
.slick-arrow.slick-next {
    right: 20px;
}

a.btn-arrow.right::before,
a.btn-arrow.slick-next::before,
.slick-arrow.right::before,
.slick-arrow.slick-next::before {
    right: 20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

a.btn-arrow:hover,
.slick-arrow:hover {
    -webkit-transform: translateY(-50%) scale(1.1);
    -moz-transform: translateY(-50%) scale(1.1);
    -ms-transform: translateY(-50%) scale(1.1);
    transform: translateY(-50%) scale(1.1);
    background: rgba(0, 0, 0, 0.9);
}

a.btn-arrow:focus,
.slick-arrow:focus {
    background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
    .slick-arrow {
        display: none !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent !important;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.d-none {
    display: none;
}

.about-hisense .second-nav {
    display: block;
}

.second-nav {
    background: rgba(0, 0, 0, 0.25);
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 1000;
    color: #ffffff;
    pointer-events: none;
    border-bottom: 1px solid #ffffff;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.second-nav li.active a {
    font-weight: 500;
}

.second-nav li.active a:before {
    width: 100%;
}

.second-nav li a {
    pointer-events: all;
    font-size: 13px;
    font-weight: 300;
    margin: 0;
    padding: 21px 0;
    color: #ffffff;
    position: relative;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    -ms-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

.second-nav li a:hover:before {
    width: 100%;
}

.second-nav li a:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    border-bottom: 2px solid #12ddd7;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.second-nav-items {
    display: flex;
    justify-content: space-between;
    padding: 21px 3.5%;
}

.second-nav-items ul {
    display: flex;
    justify-content: space-between;
    flex: 0 1 40%;
}

.second-nav-items ul:first-child {
    flex: 0 1 50%;
}

@media (max-width: 1023px) {
    .second-nav-items {
        min-width: 375px;
        width: 480px;
        padding: 16.75px 15px;
    }

    .second-nav-items ul {
        flex: 0 1 100%;
    }

    .second-nav-items ul:first-child {
        display: none;
    }

    .second-nav {
        overflow-x: auto;
        pointer-events: all;
    }

    .second-nav li a {
        font-size: 12px;
        padding: 16.75px 0;
    }
}

body.menu-in {
    overflow: hidden;
}

.searchbox {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: -200%;
    z-index: 101;
}

.searchbox .searchBg {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.searchbox .searchbar {
    margin: 0 auto;
    width: 50%;
    height: 100%;
    position: relative;
    top: -100%;
    z-index: 2;
}

.searchbox .searchbar .searchInput {
    padding: 10px 30px;
    background: #00AAA6;
    width: 100%;
    position: relative;
    z-index: 2;
}

.searchbox .searchbar .searchInput input[type="search"] {
    display: block;
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-weight: 300;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: #fff url(/.resources/webresources/images/common/icon-search.svg) no-repeat 29px center;
    background-size: auto 50%;
    border-radius: 30px;
    padding-left: 75px;
}

.searchbox .searchbar .searchInput input[type="search"]::-webkit-search-decoration,
.searchbox .searchbar .searchInput input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.searchbox .searchbar .searchBg {
    background: none;
}

.searchbox .search-down {
    display: none;
    background: #fff;
    width: 100%;
    max-height: calc(100% - 60px);
    overflow: auto;
    position: relative;
    z-index: 2;
}

.searchbox .search-down.downOpen {
    display: block;
}

.searchbox .related-list {
    position: relative;
}

.searchbox .related-list:before {
    content: "";
    display: block;
    height: 1px;
    background: #d5d5d5;
    position: absolute;
    left: 55px;
    right: 55px;
    top: 0;
}

.searchbox .text-list,
.searchbox .related-list {
    padding: 12px;
}

.searchbox .text-list dt,
.searchbox .text-list .title,
.searchbox .related-list dt,
.searchbox .related-list .title {
    font-size: 12px;
    padding-left: 42px;
    font-weight: 300;
}

.searchbox .text-list dd,
.searchbox .related-list dd {
    font-weight: 300;
}

.searchbox .text-list dd a,
.searchbox .related-list dd a {
    display: block;
    line-height: 40px;
    padding-left: 42px;
    font-size: 16px;
    color: #1d1d1f;
}

.searchbox .text-list dd a:hover,
.searchbox .related-list dd a:hover {
    background-color: #f0f0f0;
}

.searchbox .text-list dd.arrow,
.searchbox .related-list dd.arrow {
    margin-top: 20px;
}

.searchbox .text-list dd.arrow a,
.searchbox .related-list dd.arrow a {
    position: relative;
    padding-left: 42px;
    color: #686871;
    display: flex;
    align-items: self-start;
    margin-bottom: 25px;
}

.searchbox .text-list dd.arrow a:hover,
.searchbox .related-list dd.arrow a:hover {
    background-color: #f0f0f0;
}

.searchbox .text-list dd.arrow .icon-arrow,
.searchbox .related-list dd.arrow .icon-arrow {
    left: -30px;
    padding-top: 10px;
    position: relative;
}

.searchbox .text-list dd.arrow .icon-arrow:before,
.searchbox .related-list dd.arrow .icon-arrow:before {
    color: #686871;
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 8px;
    background: url(/.resources/webresources/images/common/icon-arrow.svg);
    background-repeat: no-repeat;
}

.searchbox .text-list dd .s,
.searchbox .related-list dd .s {
    color: #000;
    display: inline-block;
    line-height: 30px;
}

.searchbox .text-list dd mode,
.searchbox .related-list dd mode {
    display: inline-block;
    line-height: 30px;
}

.searchbox .pt-list {
    display: flex;
    padding-left: 40px;
    align-items: center;
    padding-bottom: 5px;
}

.searchbox .pt-list .image {
    max-width: 100px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.searchbox .pt-list .image img {
    width: 100%;
}

.searchbox .pt-list .detail {
    align-self: center;
    width: calc(100% - 100px);
}

.searchbox .pt-list .detail h3 {
    font-size: 16px;
    font-weight: 300;
    padding: 10px 0;
}

.searchbox .pt-list .detail h4 {
    font-size: 14px;
    color: #686871;
    font-weight: 300;
}

.searchbox .pt-list .detail h4 .item {
    margin-right: 20px;
}

.searchbox .pt-list .detail h4 .s {
    color: #00AAA6;
}

@media (max-width: 1023px) {
    .searchbox .searchbar {
        width: 100%;
    }

    .searchbox .searchbar .searchInput {
        padding: 10px 16px;
        display: flex;
    }

    .searchbox .searchbar a.close {
        display: block;
        width: 14px;
        height: 14px;
        background: url(/.resources/webresources/images/search/icon-close.svg) no-repeat;
        background-size: 100%;
        margin: 25px 0 0 15px;
    }

    .searchbox .related-list:before {
        left: 12px;
        right: 12px;
    }

    .searchbox .text-list dt,
    .searchbox .text-list .title,
    .searchbox .related-list dt,
    .searchbox .related-list .title {
        font-size: 16px;
        padding-left: 4px;
    }

    .searchbox .text-list dd a,
    .searchbox .related-list dd a {
        padding-left: 32px;
        font-size: 18px;
    }

    .searchbox .text-list dd.arrow a,
    .searchbox .related-list dd.arrow a {
        padding-left: 55px;
    }

    .searchbox .text-list dd.arrow a:hover,
    .searchbox .related-list dd.arrow a:hover {
        background-color: #f0f0f0;
    }

    .searchbox .pt-list {
        padding-left: 0;
    }
}

.cookie-consent {
    border: 1px solid #dedede;
    background: #fff;
    padding: 30px 30px 25px 30px;
    max-width: 430px;
    width: calc(100% - 20px);
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 500;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    display: none;
}

.cookie-consent.show {
    display: block;
}

.cookie-consent p {
    color: #5c5c5c;
    line-height: 150%;
    margin-bottom: 20px;
}

.cookie-consent p {
    font-size: 14px;
}

@media screen and (min-width: 1023px) {
    .cookie-consent p {
        font-size: calc(14px + 2 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .cookie-consent p {
        font-size: 16px;
    }
}

.cookie-consent .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cookie-consent .btn-container a {
    display: block;
    color: #00AAA6;
}

.cookie-consent .btn-container a {
    font-size: 14px;
}

@media screen and (min-width: 1023px) {
    .cookie-consent .btn-container a {
        font-size: calc(14px + 2 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .cookie-consent .btn-container a {
        font-size: 16px;
    }
}

.cookie-consent .btn-container a:hover {
    text-decoration: underline;
}

.cookie-consent .btn-container a:first-child {
    margin-right: 40px;
}

a.button {
    display: block;
    color: #fff !important;
    padding: 8px 20px;
    background-color: #00AAA6;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

a.button {
    font-size: 14px;
}

@media screen and (min-width: 1023px) {
    a.button {
        font-size: calc(14px + 2 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    a.button {
        font-size: 16px;
    }
}

a.button:hover {
    background-color: #39d5ce;
    text-decoration: none !important;
}

.cookie-consent-wrap {
    padding: 30px 40px;
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin: 20px auto;
    width: 100%;
    max-width: 990px;
}

@media (max-width: 767px) {
    .cookie-consent-wrap {
        padding: 30px 20px;
        line-height: 120%;
    }
}

.cookie-consent-wrap .heading-container {
    padding: 0 10px 10px 10px;
}

.cookie-consent-wrap .content-container {
    padding: 30px 30px 10px 30px;
    border: 1px solid #4aa7a5;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
}

.cookie-consent-wrap h2 {
    color: #1d1d1f;
    font-weight: bold;
    margin-bottom: 30px;
}

.cookie-consent-wrap h2 {
    font-size: 16px;
}

@media screen and (min-width: 1023px) {
    .cookie-consent-wrap h2 {
        font-size: calc(16px + 2 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .cookie-consent-wrap h2 {
        font-size: 18px;
    }
}

.cookie-consent-wrap h3 {
    color: #1d1d1f;
    margin-bottom: 20px;
}

.cookie-consent-wrap h3 {
    font-size: 16px;
}

@media screen and (min-width: 1023px) {
    .cookie-consent-wrap h3 {
        font-size: calc(16px + 2 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .cookie-consent-wrap h3 {
        font-size: 18px;
    }
}

.cookie-consent-wrap p {
    font-size: 12px;
    color: #1d1d1f;
    margin-bottom: 20px;
}

.cookie-consent-wrap label {
    display: flex;
    align-items: center;
}

.cookie-consent-wrap label input {
    margin-right: 15px;
}

.cookie-consent-wrap .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
}

.cookie-consent-wrap input[type="checkbox"] {
    position: relative;
    margin-right: 20px;
    padding: 0;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    transition: all 0.3s;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

.cookie-consent-wrap input[type="checkbox"]:focus {
    border: 1px solid #1d1d1f;
}

.cookie-consent-wrap input[type="checkbox"]:checked {
    background: #00AAA6;
    border-color: #00AAA6;
    -webkit-appearance: none;
    appearance: none;
}

.cookie-consent-wrap input[type="checkbox"]:checked:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/.resources/webresources/images/support/check-white.svg);
    background-size: 100%;
    transform: translate(-50%, -50%);
    content: "";
}

.mfp-bg.mfp-ready.video-lightbox {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-text-size-adjust: none;
}

body {
    width: 100%;
    min-width: 320px;
    height: 100%;
    font-weight: 400;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
figure,
figcaption {
    margin: 0;
    padding: 0;
    outline: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
    font-style: normal;
    font-weight: normal;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: "";
}

abbr,
acronym {
    border: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

ul li {
    list-style: none;
}

.important-notice {
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background-color: #009a94;
    padding: 0 3.5%;
    z-index: 99;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media (max-width: 1024px) {
    .important-notice {
        padding-left: 2%;
        padding-right: 2%;
    }
}

.important-notice.hide {
    height: 0 !important;
}

.important-notice .btn-close {
    position: absolute;
    top: 18px;
    right: 3.5%;
    width: 16px;
    height: 16px;
    z-index: 1;
}

.important-notice .btn-close:before,
.important-notice .btn-close:after {
    content: "";
    position: absolute;
    border-top: #fff 2px solid;
    width: 20px;
    height: 1px;
    top: 7px;
    left: -2px;
}

.important-notice .btn-close:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.important-notice .btn-close:after {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.important-notice .item {
    position: relative;
    width: 100%;
    display: block !important;
}

.important-notice .content {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-weight: 300;
    padding: 15px 40px;
    min-height: 55px;
}

@media (max-width: 767px) {
    .important-notice .content {
        padding-left: 0;
    }
}

.important-notice .content .desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 90px;
}

.important-notice .content a {
    color: #fff;
    text-decoration: underline;
}

.important-notice .content .btn-see-more {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 400;
}




// main slider

@import "~swiper/css";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    // text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    // display: block;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}



.mySwiper,
.mySwiperHome, .laserTvSwiper {
    .swiper-button-prev {
        left: 20px;
    }

    .swiper-button-next {
        right: 20px;
    }
}

.mySwiper .swiper-button-prev,
.mySwiperHome .swiper-button-prev,
.laserTvSwiper .swiper-button-prev,
.mySwiper .swiper-button-next,
.mySwiperHome .swiper-button-next,
.laserTvSwiper .swiper-button-next {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    background: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid #707070 !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: block;
    top: 50%;
    z-index: 50;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &::after,
    &::before {
        font-size: 20px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        opacity: .5;
    }

    &:hover {
        transform: translateY(-50%) scale(1.1);

        &::after,
        &::before {
            opacity: 1;
        }
    }
}

// @media screen and (max-width: 767px) {

//     .mySwiper,
//     .mySwiperHome {

//         .swiper-button-next,
//         .swiper-button-prev {
//             display: none;
//         }
//     }
// }

// .mySwiper .swiper-pagination {
//     width: auto !important;
//     left: 50% !important;
//     transform: translate(-50%) !important;
// }

.mySwiper .swiper-pagination-bullet,
.mySwiperHome .swiper-pagination-bullet {
    position: relative;
    width: 30px;
    height: 30px;
    border: 0;
    background: transparent;
    padding: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: none;
    opacity: 1;

    // &.swiper-pagination {
    //     display: flex !important;
    //     justify-content: center !important;
    // }

    &.swiper-pagination-bullet-active {
        border: solid 3px #E6E6E6;
    }


    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        // background-color: #FFF;
        border: 1px solid #DDD;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    &:hover {
        &::before {
            transform: translate(-50%, -50%) scale(1.25);
        }
    }
}

.mySwiper .swiper-pagination-bullet,
.mySwiperPress .swiper-pagination-bullet {
    position: relative;
    width: 30px;
    height: 30px;
    border: 0;
    background: transparent;
    padding: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: none;
    opacity: 1;

    // &.swiper-pagination {
    //     display: flex !important;
    //     justify-content: center !important;
    // }

    &.swiper-pagination-bullet-active {
        border: solid 3px #E6E6E6;
    }


    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.7);
        // border: 1px solid #DDD;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    &:hover {
        &::before {
            transform: translate(-50%, -50%) scale(1.25);
        }
    }
}



// .mySwiper .swiper-pagination-bullet .swiper-pagination-bullet-active {
//     // &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 30px;
//     height: 30px;
//     -webkit-border-radius: 50%;
//     -moz-border-radius: 50%;
//     -ms-border-radius: 50%;
//     border-radius: 50%;
//     border: solid 3px #E6E6E6;
//     opacity: 0;
//     -webkit-transform: scale(0);
//     -moz-transform: scale(0);
//     -ms-transform: scale(0);
//     transform: scale(0);
//     -webkit-transition: all 0.3s ease-out;
//     -moz-transition: all 0.3s ease-out;
//     -o-transition: all 0.3s ease-out;
//     -ms-transition: all 0.3s ease-out;
//     transition: all 0.3s ease-out;
// }
// }


.main-visual {
    width: 100%;
    // min-height: 320px;
    overflow: hidden;
}

.main-visual .slide-item {
    width: 100%;
    position: relative;
}

.main-visual .slide-item img.banner {
    width: 100%;
    height: auto;
}

.main-visual .slide-item .content {
    position: absolute;
    left: 10%;
    top: 50%;
    width: 35%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.main-visual .slide-item .content.align-right {
    left: auto;
    right: 5%;
}

.main-visual .slide-item .content.align-center {
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    text-align: center;
}

@media (max-width: 767px) {
    .main-visual .slide-item .content.align-center {
        right: auto !important;
    }
}

.main-visual .slide-item .content.align-center img {
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-50%) !important;
}

.main-visual .slide-item .content.button-align-center {
    bottom: 12%;
    left: 50% !important;
    top: auto !important;
    transform: translateX(-50%) !important;
    text-align: center;
}

.main-visual .slide-item .content.button-align-center img {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 767px) {
    .main-visual .slide-item .content {
        width: 80%;
        top: 8%;
        // left: 10% !important;
        // right: 10% !important;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.main-visual .slide-item .content img {
    display: block;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .main-visual .slide-item .content img {
        width: 142px;
        margin: 0 auto 5px auto;
    }
}

.main-visual .slide-item .content h2 {
    color: #fff;
    letter-spacing: -0.5px;
    line-height: 100%;
    font-weight: bold;
    margin-bottom: 15px;
}

.main-visual .slide-item .content h2 {
    font-size: 38px;
}

@media screen and (min-width: 1023px) {
    .main-visual .slide-item .content h2 {
        font-size: calc(38px + 24 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .main-visual .slide-item .content h2 {
        font-size: 62px;
    }
}

@media (max-width: 1023px) {
    .main-visual .slide-item .content h2 {
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .main-visual .slide-item .content h2 {
        font-size: 38px;
        line-height: 120%;
        text-align: center;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }
}

.main-visual .slide-item .content p {
    color: #fff;
    line-height: 120%;
    margin-bottom: 45px;
    width: 100%;
}

.main-visual .slide-item .content p {
    font-size: 16px;
}

@media screen and (min-width: 1023px) {
    .main-visual .slide-item .content p {
        font-size: calc(16px + 8 * ((100vw - 1023px) / 417));
    }
}

@media screen and (min-width: 1440px) {
    .main-visual .slide-item .content p {
        font-size: 24px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main-visual .slide-item .content p {
        margin-bottom: 10px;
    }
}

@media (max-width: 1023px) {
    .main-visual .slide-item .content p {
        margin-bottom: 25px;
    }
}

@media (max-width: 767px) {
    .main-visual .slide-item .content p {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        width: 70%;
        margin: 0 auto 30px auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main-visual .slide-item .content a.btn-hisense {
        padding: 15px;
        min-width: 180px;
        height: 45px;
    }
}

@media (max-width: 767px) {
    .main-visual .slide-item .content a.btn-hisense {
        margin: 0 auto;
        width: 178px;
        min-width: 0;
    }
}

@media screen and (min-width: 1186px) {
    .main-visual .slide-item .content a.btn-hisense:first-of-type {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1185px) {
    .main-visual .slide-item .content a.btn-hisense:first-of-type {
        margin-bottom: 15px !important;
    }
}


//slider dots
.main-visual .slick-dots {
    bottom: 30px;
    width: auto;
    left: 50%;
    padding: 10px 19px;
    display: flex;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.main-visual .slick-dots li {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    display: block;
}

.main-visual .slick-dots li button {
    position: relative;
    width: 30px;
    height: 30px;
}

.main-visual .slick-dots li button:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.main-visual .slick-dots li button:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: solid 3px #e6e6e6;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.main-visual .slick-dots li button:hover:before {
    opacity: 1;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}

.main-visual .slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: #ddd;
}

.main-visual .slick-dots li.slick-active button:after {
    opacity: 0.5;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.terms__and__conditions__context__text {
    p {
        margin-bottom: 16px !important;
    }

    ul,
    li {
        list-style: disc !important;
    }

    ul {
        padding-left: 16px !important;
    }
}

@media screen and (max-width:768px) {
    .page__title {
        font-size: 40px !important;
    }
}

.breadcrumb__yellow__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #00AAA6;
    color: #FFF;
    padding: 6px !important;

    h1 {
        text-transform: uppercase;
        font-weight: 400 !important;
        font-size: 20px !important;
        margin: 0 !important;
    }
}

// .card {
//     margin: 10px;
//     width: 300px;
//     background: #fff;
//     border-radius: 5px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

//     .image {
//         img {
//             max-width: 100%;
//             border-top-left-radius: 5px;
//             border-top-right-radius: 5px;
//         }
//     }

//     .content {
//         padding: 20px 30px;
//     }
// }

.card.is-loading {

    .image,
    .tabs,
    &.page__wrapper {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    .image,
    .tabs,
    &.page__wrapper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

a {
    color: #00AAA6 !important;
}

html {
    scroll-behavior: smooth !important;
}

.product-listing-tab::before {
    box-shadow: 0 3px 4px rgb(0 0 0 / 18%);
    border-radius: 0 !important;
}

header {
    a {
        color: #1d1d1f !important;
    }
}

footer {
    a {
        color: white !important;
    }

    dd {
        a {
            color: #00AAA6 !important;
        }
    }
}

.btn-hisense-v2 {
    pointer-events: all;
    opacity: 1;
    background-color: #00aaa6;
    white-space: nowrap;
    text-align: center;
    font-weight: 400;
    padding: 10px 24px !important;
    font-size: 14px !important;
    border-radius: 32px !important;
    // background: linear-gradient(90deg, #6debe6 0%, #39d5ce 10%, #00aaa6 40%, #00AAA6 60%);
    background-size: 200%;
    display: block;
    width: auto !important;
    display: inline-block !important;
    transition: all 300ms ease-in-out;
    background: white !important;
    border: 1px solid black !important;
    color: black !important;
    // * {
    //     color: #00AAA6 !important;
    // }

    &:hover {
        background: #00AAA6 !important;
        // background: linear-gradient(90deg, #067977 0%, #067977 10%, #067977 40%, #067977 60%);
        color: #fff !important;
        border-color: white !important;

    }
}

a.active {
    color: white !important;
}

.error__message {
    color: #a50034;
    display: flex;
    align-items: center;
    margin: 8px 4px;
    font-size: 14px;
    line-height: 14px;
}

.error__message::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-bottom: 3px;
    margin-right: 4px;
    background: url(./assets/error.svg) no-repeat 0 0;
}

.font-14 {
    font-size: 14px;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

select::placeholder {
    font-weight: 100 !important;
}

option {
    font-weight: 100 !important;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.tabs__container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 16px 0;

    .tabs {
        // background: #eae9e9;
        border-radius: 50px;
        padding: 20px;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .list-group {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        width: 100%;
        gap: 16px;

        a {
            padding: 0.75rem 1.25rem;
            margin-bottom: -1px;
            background-color: #fff;
            white-space: nowrap;
            border: 1.2px solid #09cbc6;
            border-radius: 16px;
        }

        .active {
            color: #fff;
            // background-color: #2FA4E7 !important;
            background-color: #00AAA6 !important;
            border-color: #00AAA6 !important;
        }
    }
}


@media screen and (max-width: 768px) {
    .tabs__container {
        .tabs {
            padding: 12px;
        }

        .list-group {
            gap: 12px;

            a {
                padding: 0.75rem 1rem;
                border: 1.2px solid #09cbc6;
                border-radius: 16px;
            }
        }
    }
}

.flex-row{
    flex-direction: row !important;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: -50px !important;
}

.pos-relative{
    position: relative;
    .pos-abolute-center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.h-100{
    height: 100%;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-center{
    text-align: center;
}

.cursor-pointer{
    cursor: pointer;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.purchase__link__image{
    width: 150px;
    height: 60px;
}
.purchase__link__image.sales_india {
    border-radius: 0 !important;
    background-color: #2d327d;
}

// marketing video styles
.video-container {
    position: relative;
    margin-bottom: 10px;
    &.about-container{
        padding-bottom: 8px !important;
        padding-top: 32px !important;
    }
}
.video-container .content {
    position: absolute;
    left: 20%;
    top: 10%;
    z-index: 10;
    width: 60%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 767px) {
    .video-container .content {
        left: 5%;
        width: 90%;
    }
}
.video-container .content h3 {
    color: #fff;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 90px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.video-container .content h3 {
    font-size: 32px;
}
@media screen and (min-width: 1023px) {
    .video-container .content h3 {
        font-size: calc(32px + 30 * ((100vw - 1023px) / 417));
    }
}
@media screen and (min-width: 1440px) {
    .video-container .content h3 {
        font-size: 62px;
    }
}

.hisense__intro__video{
    height: 700px;
}

@media (max-width: 767px) {
    .video-container .content h3 {
        font-size: 32px;
        margin-bottom: 50px;
    }
    .hisense__intro__video{
        height: 250px;
    }
}
.video-container .content h3 strong {
    color: #00AAA6;
}
.video-container .content a.btn-video {
    display: block;
    font-size: 16px;
    color: #1d1d1f;
    padding: 18px 45px 18px 70px;
    background: url("../src/assets/play.svg") 20px 12px #fff no-repeat;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}
.video-container .content a.btn-video:hover {
    background-color: #ddd;
}
.video-container .banner img {
    display: block;
    width: 100%;
    height: auto;
}

.video {
    position: relative;
    overflow: hidden;
    background: #202020;
    width: 100%;
    height: 100%;
}

.video {
    height: auto !important;
}

.video__element {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    transition: 1s opacity;
    position: relative;
}

.video .video__element {
    width: 100%;
    margin: 0;
}